import React from 'react';
import * as styles from './StaticPage.module.scss';
import ContentWrap from '../ContentWrap/ContentWrap';

type StaticPageProps = {
  children: React.ReactNode;
  title: string | React.ReactNode;
  date: string | React.ReactNode;
};
const StaticPage = ({
  children,
  title,
  date,
}: StaticPageProps): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <ContentWrap>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.date}>{date}</div>
        {children}
      </ContentWrap>
    </div>
  );
};

export default StaticPage;
