// extracted by mini-css-extract-plugin
export var antBtnLg = "StaticPage-module--ant-btn-lg--ue876";
export var antBtnPrimary = "StaticPage-module--ant-btn-primary--Wg7s9";
export var antFormItem = "StaticPage-module--ant-form-item--SA-3u";
export var antFormItemLabel = "StaticPage-module--ant-form-item-label--0XZWh";
export var antInput = "StaticPage-module--ant-input--Lzelp";
export var antMessageNotice = "StaticPage-module--ant-message-notice--13vcO";
export var antMessageNoticeContent = "StaticPage-module--ant-message-notice-content--R+4bt";
export var date = "StaticPage-module--date--wbify";
export var errorMessage = "StaticPage-module--errorMessage--QnyYV";
export var title = "StaticPage-module--title--AY6Ee";
export var wrapper = "StaticPage-module--wrapper--TdHlb";