import React from 'react';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import StaticPage from '../StaticPage/StaticPage';
import { PRIVACY_POLICY_ROUTE, SUPPORT_EMAIL_ROUTE } from '../../const/routes.const';

const Eula = (): React.ReactElement => {
  const { language } = useI18next();

  const systemRequirementsBlogLinks = {
    en: 'https://docs.octobrowser.net/en/start/system-requirements/',
    ru: 'https://docs.octobrowser.net/start/system-requirements/'
  };

  return (
    <StaticPage
      date={<Trans>This document was last updated on January 4, 2023</Trans>}
      title={<Trans>End-User License Agreement (EULA) of Octo Browser</Trans>}
    >
      <p>
        <Trans>
          BY DOWNLOADING, INSTALLING AND/OR USING OCTO BROWSER SOFTWARE (“SOFTWARE”) AND MAKING
          PURCHASES YOU AGREE TO THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS STOP USING THE
          SOFTWARE AND UNINSTALL IT, AS WELL AS DO NOT PROCEED TO MAKE ANY PURCHASES.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="BLACKBIRD SECURE BROWSING LTD, incorporated under the laws of the UK (“OCTO”, “Company”, “we” or “us”), commercial registry code: 13404707, license you to use the Software as this term defined above.">
          BLACKBIRD SECURE BROWSING LTD, incorporated under the laws of the UK (“OCTO”, “Company”,
          “we” or “us”), <strong>commercial registry code:</strong> 13404707, license you to use the
          Software as this term defined above.
        </Trans>
      </p>
      <p>
        <Trans>
          This End-User License Agreement (“EULA”) is a legal agreement between you and OCTO.
        </Trans>
      </p>
      <p>
        <Trans>
          This EULA agreement governs your acquisition and use of our Software directly from BLACKBIRD
          SECURE BROWSING LTD or indirectly through BLACKBIRD SECURE BROWSING LTD authorized reseller
          or distributor (a “Reseller”).
        </Trans>
      </p>
      <p>
        <Trans>
          Please read this EULA agreement carefully before completing the installation process and
          using the Software. It provides a license to use the Software and contains warranty
          information and liability disclaimers.
        </Trans>
      </p>
      <p>
        <Trans>
          If you register for a free trial of the Software, this EULA will also govern that trial. By
          clicking “accept” or installing and/or using the Software, you are confirming your
          acceptance of the Software and agreeing to become bound by the terms of this EULA.
        </Trans>
      </p>
      <p>
        <Trans>
          If you are entering into this EULA on behalf of a company or other legal entity, you
          represent that you have the authority to bind such entity and its affiliates to these terms
          and conditions. If you do not have such authority or if you do not agree with the terms and
          conditions of this EULA , do not install or use the Software, and you must not accept this
          EULA.
        </Trans>
      </p>
      <p>
        <Trans>
          This EULA shall apply only to the Software supplied by BLACKBIRD SECURE BROWSING LTD
          (excluding the object code and source code) herewith regardless of whether other software is
          referred to or described herein. The terms also apply to any BLACKBIRD SECURE BROWSING LTD
          updates, supplements, Internet-based services, and support services for the Software, unless
          other terms accompany those items on delivery. If so, those terms apply.
        </Trans>
      </p>
      <h5>
        <Trans>License Grant</Trans>
      </h5>
      <p>
        <Trans>
          The Company hereby grants you a personal, limited, non-transferable, non-exclusive,
          non-sublicensable, worldwide license to use the Software on your devices in accordance with
          the terms of this EULA.
        </Trans>
      </p>
      <h5>
        <Trans>You are permitted to:</Trans>
      </h5>
      <ul>
        <li>
          <Trans i18nKey="download and/or install the Software onto an unlimited number of devices (for example a PC, laptop, mobile phones or tablet, and other portable and handheld devices) under your control for your personal purposes only. You are responsible for ensuring your device meets the minimum requirements of the Software (you can find the minimum requirements of the Software here);">
            download and/or install the Software onto an unlimited number of devices (for example a
            PC, laptop, mobile phones or tablet, and other portable and handheld devices) under your
            control for your personal purposes only. You are responsible for ensuring your device
            meets the minimum requirements of the Software (you can find the minimum requirements of
            the Software <a
            href={systemRequirementsBlogLinks[language as keyof typeof systemRequirementsBlogLinks] || systemRequirementsBlogLinks.en}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
            );
          </Trans>
        </li>
        <li>
          <Trans>
            receive and use any free supplementary software code or update of the Software
            incorporating corrections of errors as we may provide to you.
          </Trans>
        </li>
      </ul>
      <h5>
        <Trans>You are not permitted to:</Trans>
      </h5>
      <ul>
        <li>
          <Trans>
            edit, alter, modify, adapt, translate, create derivative works of the Software or
            otherwise change the whole or any part of the Software nor permit the whole or any part of
            the Software to be combined with or become incorporated in any other software, nor
            decompile, disassemble or reverse engineer, attempt to derive the source code of the
            Software or attempt to do any such things;
          </Trans>
        </li>
        <li>
          <Trans>
            reproduce, copy, distribute, resell or otherwise use the Software for any commercial
            purpose;
          </Trans>
        </li>
        <li>
          <Trans>
            allow any third party to use the Software on behalf of or for the benefit of any third
            party;
          </Trans>
        </li>
        <li>
          <Trans>
            use the Software in any way which breaches any applicable local, national or international
            law;
          </Trans>
        </li>
        <li>
          <Trans>
            use the Software for any purpose that OCTO considers is a breach of this EULA.
          </Trans>
        </li>
      </ul>
      <h5>
        <Trans>Intellectual Property and Ownership</Trans>
      </h5>
      <p>
        <Trans>
          BLACKBIRD SECURE BROWSING LTD shall at all times retain ownership of the Software as
          originally downloaded by you and all subsequent downloads of the Software by you. The
          Software (and the copyright, and other intellectual property rights of whatever nature in
          the Software, including any modifications made thereto) are and shall remain the property of
          BLACKBIRD SECURE BROWSING LTD. You acknowledge and agree that the Software is licensed and
          not sold to you, and no title or ownership to such Software or the intellectual property
          rights embodied therein is granted to you.
        </Trans>
      </p>
      <p>
        <Trans>
          BLACKBIRD SECURE BROWSING LTD reserves the right to grant licenses to use the Software to
          third parties.
        </Trans>
      </p>
      <p>
        <Trans>
          All trademarks, service marks and trade names are owned, registered and/or licensed by
          BLACKBIRD SECURE BROWSING LTD and/or Reseller. You do not acquire a license or any ownership
          rights to any trademarks, service marks, or trade names according to the EULA.
        </Trans>
      </p>
      <h5>
        <Trans>Privacy</Trans>
      </h5>
      <p>
        <Trans i18nKey={"Your privacy is really important to us. We recommend you, and you should ensure that you have read the Privacy Policy provisions carefully. You can find our Privacy Policy here."}>Your privacy is really important to us. We recommend you, and you should ensure that you have read the Privacy Policy provisions carefully. You can find our Privacy Policy <Link to={PRIVACY_POLICY_ROUTE}>here</Link>.</Trans>
      </p>
      <h5>
        <Trans>Limitation of Liability</Trans>
      </h5>
      <p>
        <Trans>
          TO THE FULLEST EXTENT OF APPLICABLE LAW, COMPANY SHALL NOT BE LIABLE FOR SPECIAL,
          INCIDENTAL, OR CONSEQUENTIAL DAMAGES RESULTING FROM POSSESSION, USE, OR MALFUNCTION OF THE
          SOFTWARE, INCLUDING, BUT NOT LIMITED TO, DAMAGES TO PROPERTY, LOSS OF GOODWILL, COMPUTER
          FAILURE OR MALFUNCTION, AND, TO THE EXTENT PERMITTED BY LAW, DAMAGES FOR PERSONAL INJURIES,
          PROPERTY DAMAGE, OR LOST PROFITS OR PUNITIVE DAMAGES FROM ANY CAUSES OF ACTION ARISING OUT
          OF OR RELATED TO THIS EULA OR THE SOFTWARE, WHETHER ARISING IN TORT (INCLUDING NEGLIGENCE),
          CONTRACT, STRICT LIABILITY, OR OTHERWISE, WHETHER OR NOT LICENSOR HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT OF APPLICABLE LAW, COMPANY'S LIABILITY
          FOR ALL DAMAGES WILL AT ALL TIMES BE LIMITED TO THE AMOUNT YOU’VE PAID OCTO IN THE PRIOR 12
          MONTHS (IF ANY).
        </Trans>
      </p>
      <h5>
        <Trans>Disclaimer of Warranty</Trans>
      </h5>
      <p>
        <Trans>
          While OCTO makes every effort to deliver high-quality products, we do not guarantee that our
          products are free from defects. Our Software is provided “as is”, and you use the Software
          at your own risk. We make no warranties as to performance, merchantability, fitness for a
          particular purpose, or any other warranties whether expressed or implied. No oral or written
          communication from or information provided by OCTO shall create a warranty. Under no
          circumstances shall OCTO be liable for direct, indirect, special, incidental, or
          consequential damages resulting from the use, misuse, or inability to use the Software, even
          if OCTO has been advised of the possibility of such damages.
        </Trans>
      </p>
      <p>
        <Trans>
          OCTO may not be available in all languages or in all countries, and we make no
          representation that the functionality would be appropriate, accurate or available for use in
          any particular location. OCTO availability and pricing is subject to change.
        </Trans>
      </p>
      <p>
        <Trans>
          Updates may modify or delete certain features and/or functionalities of the Software. You
          agree that the Company has no obligation to (i) provide any updates to the Software, or (ii)
          continue to provide or enable any particular features and/or functionalities of the Software
          to you.
        </Trans>
      </p>
      <p>
        <Trans>This disclaimer constitutes an essential part of this EULA.</Trans>
      </p>
      <h5>
        <Trans>Termination</Trans>
      </h5>
      <p>
        <Trans>
          This EULA is effective from the date you first use the Software and shall continue until
          terminated. You may terminate it at any time upon written notice to BLACKBIRD SECURE
          BROWSING LTD.
        </Trans>
      </p>
      <p>
        <Trans>
          It will also terminate immediately if (1) you fail to comply with any term of this EULA
          agreement or governing law, and/or (2) system failure or any other circumstances beyond
          OCTO's control.
        </Trans>
      </p>
      <p>
        <Trans>
          We will do our best to send you advance notification, but we cannot guarantee that we will
          be able to send a notification under any circumstances.
        </Trans>
      </p>
      <p>
        <Trans>
          Upon any termination, the licenses granted by this EULA will immediately terminate and you
          agree to stop all access and use of the Software. The provisions that by their nature
          continue and survive will survive any termination of this EULA agreement.
        </Trans>
      </p>
      <p>
        <Trans>
          We may terminate this EULA and your access to OCTO if you violate the terms of this EULA.
          Upon any termination of the EULA, you must promptly uninstall the Software on all of your
          devices and destroy all materials downloaded or otherwise obtained from the Software, all
          documentation, and all copies of such materials. The following provisions survive the
          expiration or termination of this EULA for any reason whatsoever: Intellectual Property and
          Ownership, Disclaimer of Warranty, Governing Law.
        </Trans>
      </p>
      <h5>
        <Trans>Indemnification</Trans>
      </h5>
      <p>
        <Trans>
          You agree to indemnify, defend, and hold harmless OCTO, its affiliates, directors, officers,
          employees, and agents from and against any and all liabilities arising from any claims due
          to, arising out of, or relating in any way to (a) any breach of this EULA by you; or (b) any
          gross negligence or willful misconduct of you.
        </Trans>
      </p>
      <p>
        <Trans>
          OCTO reserves the right, at your expense, to assume the exclusive defense and control of any
          matter for which you are required to indemnify OCTO.
        </Trans>
      </p>
      <h5>
        <Trans>Changes to this EULA</Trans>
      </h5>
      <p>
        <Trans>
          OCTO may update the terms of this EULA from time to time, you waive any right to receive
          specific notice of each such change. Please check this page on a regular basis.
        </Trans>
      </p>
      <h5>
        <Trans>Governing Law</Trans>
      </h5>
      <p>
        <Trans>
          This EULA, and any dispute arising out of or in connection with this EULA , shall be
          governed by and construed in accordance with the laws of the UK without giving any conflicts
          of law principles that may require the application of the laws of a different country. If
          any provisions of this EULA are determined by a court of competent jurisdiction to be
          invalid, illegal, or unenforceable, the remaining provisions of this EULA shall not be
          affected or impaired thereby.
        </Trans>
      </p>
      <h5>
        <Trans>Notices, feedback and Electronic communication</Trans>
      </h5>
      <p>
        <Trans>
          Any notice or other communication under this EULA shall be by email. The language of the
          communication shall be English or Russian.
        </Trans>
      </p>
      <p>
        <Trans>
          You understand that any comments, feedback, or ideas you send us (including in our social
          media) are provided on a non-confidential basis and you grant to OCTO a perpetual, worldwide
          license to use all comments, feedback and ideas you may share with us, without notice,
          compensation or acknowledgement to you, for any purposes whatsoever, including, but not
          limited to, developing, manufacturing and marketing products and services and creating,
          modifying or improving products and services.
        </Trans>
      </p>
      <h5>
        <Trans>Assignment</Trans>
      </h5>
      <p>
        <Trans>
          This EULA, and your rights and obligations herein, may not be assigned by you without OCTO’s
          prior written consent, and any attempted assignment in violation of the foregoing will be
          null and void. OCTO may assign its rights obligations in connection with a merger,
          acquisition, reorganization or sale of all or substantially all of its assets, or other
          operation of law, without your consent. This EULA shall be binding upon assignees.
        </Trans>
      </p>
      <h5>
        <Trans>Contact us</Trans>
      </h5>
      <p>
        <Trans i18nKey="If you want to learn more about the Software or have any problems using it or you think that there is a mistake in this EULA or require any changes or you wish to contact us for any other reason, please feel free to email us.">
          If you want to learn more about the Software or have any problems using it or you think that
          there is a mistake in this EULA or require any changes or you wish to contact us for any
          other reason, please feel free to <a href={`mailto:${SUPPORT_EMAIL_ROUTE}`}>email us</a>.
        </Trans>
      </p>
    </StaticPage>
  );
};

export default Eula;
